import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"


const OurBlog2 = ({ data }) => {    
  return (
      <section className="blog-area pt-100 pb-70 bg-fffbf5">
          <div className="container">
              <div className="section-title">
                  <span className="sub-title">
                      <StaticImage src="../assets/images/star-icon.png" alt="blog" /> 
                      Our Blog
                  </span>
                  <h2>新着情報・事例の紹介</h2>
              </div>
              <div className="row">

               {data.allMicrocmsBlog.edges.map(({ node }) => (
        <div className="col-lg-4 col-md-6" key={node.slug}>
                      <div className="single-blog-post bg-fffbf5">
                          <div className="post-image">
                          <Link to={`/blog/${node.blogId}`}>

                              {node.eyecatch ? (
          <img
            src={node.eyecatch.url}
            as={`figure`}
            style={{ aspectRatio: "25/14" }}
          />
        ) : (
          <StaticImage
            src={`../assets/images/explore-learning.jpg`}
            alt=""
            as={`figure`}
            style={{ aspectRatio: "25/14" }}
          />
        )}
                              </Link>
                          </div>

                          <div className="post-content">
                              <ul className="post-meta d-flex justify-content-between align-items-center">
                                  <li>
                                      <div className="post-author d-flex align-items-center">
                                      
                                      Bgreen Staff
                                      </div>
                                  </li>
                                  <li>
                                      <i className='flaticon-calendar'></i>{node.date}
                                  </li>
                              </ul>
                              <h3>
                                  <Link to={node.uri}>
                                      {node.title}
                                  </Link>
                              </h3>
                          </div>
                      </div>
                  </div>
        ))}
                  
              </div>

          </div>
      </section>
  )
}

export default OurBlog2

export const query = graphql`
query {
allMicrocmsBlog(limit:3){
  edges {
    node {
      blogId
      title
      publishedAt(formatString: "YYYY/MM/DD")
      eyecatch {
        url
      }             
    }
  }
}
} 
`